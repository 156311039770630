/* styles.css */
.container {
  width: 100%;
  padding: 20px;
}

.black-bar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}
.how-works-container {
  display: flex;
  flex-direction: row;
  padding: 50px 0px;
  align-items: flex-start;
  justify-content: 'center';
}
.how-it-works-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  gap: 8px;
}

.how-works-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  vertical-align: middle;
}

.divider-line {
  background-color: #6a6666;
  height: 50px;
  width: 1px;
  margin: 0px 50px;
}

.divider-line-hr {
  background-color: #6a6666;
  width: 80%;
  height: 1px;
  margin: 32px 0px;
}

.dashed-line-div {
  margin-top: 25px;
  border-top: 4px dashed #d9d9d9;
  flex: 1;
  height: 1px;
}
.ellipse-blue-blur {
  background-color: #2884ffcc;
  border-radius: 232px/100px;
  filter: blur(200px);
  height: 200px;
  width: 464px;
  right: 100px;
  margin-top: 600px;
  position: absolute;
}
.watch-video {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.popular-creations {
  background-color: transparent;
  margin: 16px 0px 40px 0px;
  width: 100%;
}
.popular-creations-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px 16px;
  border-radius: 10px;
}

.popular-creations-inner span {
  margin-top: 8px;
  font-family: 'Inter-Regular';
  font-size: 15;
  color: #746f6f;
}

.buttons-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.gradient-container {
  display: flex;
  width: auto;
  flex-direction: column;
  background: linear-gradient(#fff, #eee);
}

.footer {
  background-color: transparent;
}
.group-243-B48oaP {
  background-color: transparent;
  height: 135px;
  left: 120px;
  position: absolute;
  top: 43px;
  width: 135px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .how-works-container {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .dashed-line-div {
    margin: 0px 0px;
    height: 0px;
  }

  .how-it-works-item {
    width: 50%;
    gap: 8px;
  }
}
