/********** Template CSS **********/
:root {
  --primary: #2884ff;
  --light: #ffffff;
  --dark: #211e1e;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s ease-out,
    visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition:
    opacity 0.5s ease-out,
    visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Section Title ***/
.section-title h1 {
  position: relative;
  display: inline-block;
  padding: 0 60px;
}

.section-title.text-start h1 {
  padding-left: 0;
}

.section-title h1::before,
.section-title h1::after {
  position: absolute;
  content: '';
  width: 45px;
  height: 5px;
  bottom: 0;
  background: var(--dark);
}

.section-title h1::before {
  left: 0;
}

.section-title h1::after {
  right: 0;
}

.section-title.text-start h1::before {
  display: none;
}

/*** About ***/
@media (min-width: 992px) {
  .container.about {
    max-width: 100% !important;
  }

  .about-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .about-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .about-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Service ***/
.service-item img {
  transition: 0.5s;
}

.service-item:hover img {
  transform: scale(1.1);
}

/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
    max-width: 100% !important;
  }

  .feature-text {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Project Portfolio ***/
#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  transition: 0.5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--primary);
  border-color: var(--primary);
}

.portfolio-item img {
  transition: 0.5s;
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio-item .portfolio-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  bottom: 0;
  left: 50%;
  background: rgba(53, 53, 53, 0.7);
  transition: 0.5s;
}

.portfolio-item:hover .portfolio-overlay {
  width: 100%;
  height: 100%;
  left: 0;
}

.portfolio-item .portfolio-overlay .btn {
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay .btn {
  opacity: 1;
}

/*** Quote ***/
@media (min-width: 992px) {
  .container.quote {
    max-width: 100% !important;
  }

  .quote-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .quote-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .quote-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Team ***/
.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item .team-social {
  position: absolute;
  width: 38px;
  top: 50%;
  left: -38px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  background: #ffffff;
  transition: 0.5s;
}

.team-item .team-social .btn {
  color: var(--primary);
  background: #ffffff;
}

.team-item .team-social .btn:hover {
  color: #ffffff;
  background: var(--primary);
}

.team-item:hover .team-social {
  left: 0;
}

/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
}

.testimonial-carousel .owl-item .testimonial-text {
  border: 5px solid var(--light);
  transform: scale(0.8);
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  transform: scale(1);
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 350px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
  width: 300px;
  opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  color: var(--primary);
  font-size: 45px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

/*** Contact ***/
@media (min-width: 992px) {
  .container.contact {
    max-width: 100% !important;
  }

  .contact-text {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .contact-text {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .contact-text {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Footer ***/
.footer {
  background-color: var(--light);
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  border: 1px solid #ffffff;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--dark);
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}
.d-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
}

.footer h5 {
  font-size: 36;
  font-family: 'Inter-Regular';
  font-weight: 600;
}

.footer .copyright {
  padding: 18px 0;
  font-size: 15px;
  border-top: 1px solid #d9d9d9;
}

.footer .copyright a {
  color: var(--light);
}

.footer .copyright a:hover {
  color: var(--primary);
}
